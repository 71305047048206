import classnames from 'classnames';

import useTheme, { THEME } from '@/hooks/useTheme';

function Heading({
  children,
  level = 'h1',
  visualLevel,
  tag,
  uppercase,
  position,
  weight,
  lineHeight,
  whiteSpace,
  font,
  color,
  className,
  ...props
}) {
  const { theme } = useTheme();
  const Tag = tag || level;
  const visual = visualLevel || level;

  return (
    <Tag
      className={classnames(
        {
          'text-h2 sm:text-h1+': visual === 'h1+',
          'text-h2 sm:text-h1': visual === 'h1',
          'text-h3+ sm:text-h2': visual === 'h2',
          'text-h3+': visual === 'h3+',
          'text-h3': visual === 'h3',
          'text-h4': visual === 'h4',
          'text-h5': visual === 'h5',
          'text-h6': visual === 'h6',
          'font-normal': weight === 'normal',
          'font-bold': weight === 'bold',
          'font-heading': font === 'heading',
          'text-left justify-start': position === 'left',
          'text-center justify-center': position === 'center',
          'text-right justify-end': position === 'right',
          uppercase: uppercase,
          'leading-none': lineHeight === '1',
          'whitespace-nowrap': whiteSpace === 'nowrap',
          'text-primary': color === 'primary',
          'text-white': color === 'white',
          'text-secondary-tamula': color === 'secondary-tamula',
          'text-secondary-lahemaa': color === 'secondary-lahemaa',
          'text-national-park-vilsandi': color === 'national-park-vilsandi',
        },
        className,
      )}
      {...props}
    >
      <span>{children}</span>
    </Tag>
  );
}

export default Heading;
