export const THEME = Object.freeze({
  DEFAULT: 'DEFAULT',
  CHRISTMAS2022: 'CHRISTMAS2022',
});

function useTheme() {
  const isMaintenanceMode = process.env.NEXT_PUBLIC_MAINTENANCE_MODE === 'true';
  const theme = isMaintenanceMode ? THEME.DEFAULT : THEME?.[process.env.NEXT_PUBLIC_THEME] ?? THEME.DEFAULT;

  return {
    theme,
  };
}

export default useTheme;
