import Link from 'next/link';
import { useRouter } from 'next/router';
import classNames from 'classnames';

import PuhkaEestisLogo from '../../assets/svg/puhka_eestis_vertical_positive.svg';
import VisitEstoniaLogo from '../../assets/svg/visit_estonia_vertical_postive.svg';

function Logo({ className }) {
  const router = useRouter();
  const LogoSVG = router.locale === 'et' ? PuhkaEestisLogo : VisitEstoniaLogo;

  return (
    <Link href="/">
      <a className={classNames(className)}>
        <span className="sr-only">Puhka Eestis</span>
        <LogoSVG className="h-20 w-20 md:h-[6.25rem] md:w-[6.25rem]" />
      </a>
    </Link>
  );
}

export default Logo;
