import axios from 'axios';
import * as Sentry from '@sentry/nextjs';

const API_BASE_URL = process.env.NEXT_PUBLIC_API_URL;

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    Sentry.captureException(error);
    throw error;
  },
);

export const queryKeys = {
  getSellerObjects: 'getSellerObjects',
  getOrderById: 'getOrderById',
  createTransaction: 'createTransaction',
  getSellerObjectById: 'getSellerObjectById',
  getInvoiceData: 'getInvoiceData',
  getSellerObjectBySlug: 'getSellerObjectBySlug',
  getAllSellerObjectsSlugs: 'getAllSellerObjectsSlugs',
  getSimilarSellerObjectById: 'getSimilarSellerObjectById',
};

const BackendClient = {
  getConfiguration: () => axios.get(`${API_BASE_URL}/configuration`),
  getTranslations: () => axios.get(`${API_BASE_URL}/translations`),
  getSellerObjects: ({ language, limit = 10, ...params }) =>
    axios.get(`${API_BASE_URL}/seller-objects`, {
      params: {
        language,
        limit,
        ...params,
      },
    }),
  searchSellerObjects: (searchText, language) =>
    axios.get(`${API_BASE_URL}/seller-objects/search`, {
      params: {
        searchText,
        language,
      },
    }),
  getCountedTypes: (params) =>
    axios.get(`${API_BASE_URL}/seller-objects/get-counted-types`, {
      params,
    }),
  getAllSellerObjectsSlugs: ({ limit }) =>
    axios.get(`${API_BASE_URL}/seller-objects/find-all-slugs`, { params: { limit } }),
  getSellerObjectById: (id) => axios.get(`${API_BASE_URL}/seller-objects/find-by-id`, { params: { id } }),
  getSimilarSellerObjectById: ({ id, language }) =>
    axios.get(`${API_BASE_URL}/seller-objects/find-similar-by-id`, { params: { id, language } }),
  getSellerObjectBySlug: (slug) => axios.get(`${API_BASE_URL}/seller-objects/find-by-slug`, { params: { slug } }),
  getOrderById: (id) => axios.get(`${API_BASE_URL}/orders/${id}`),
  createOrder: (order) => axios.post(`${API_BASE_URL}/orders`, order),
  updateOrder: (order) => axios.put(`${API_BASE_URL}/orders`, order),
  createTransaction: (data) => axios.post(`${API_BASE_URL}/makecommerce/create-transaction`, data),
  getInvoiceData: (id) => axios.get(`${API_BASE_URL}/orders/${id}/invoice`),
};

export default BackendClient;
